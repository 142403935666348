import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../services/config.service";
import {DownloadUtilService} from '../../app/util/download-util.service';
import { Assigned, ICountStatus, IDeliveryStatus, ILocationList, IMmdDeliveredGroupAnalytics, IO2DOverviewAnalytics, IO2dTemperatureGraph, IO2dtimeSlot, IRiderWisePerformance, ITatOfOrder, ITemperatureDetails, ITotalOrderKiosk, IWarehouseZoneList } from "./interface";

@Injectable({
    providedIn : 'root'
})
export class dashboardService{

    constructor(private http: HttpClient, public configService: ConfigService, private downloadService: DownloadUtilService)
    {}
    effectGetAllAssignmentsCountForOrderStatsByGivenStatus(obj) {
        let queryUrl =
          `${this.configService.appConfig.appBaseUrl}deliveryManagement/management/count`;
        if (obj.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj.startDate.format('x')}`;
        }
        if (obj.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj.endDate.format('x')}`;
        }
        if (obj.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj.orderPickUpCityId}`;
        }
        if (obj.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj.orderPickUpStoreId}`;
        }
        if (obj.providedOrderStatus) {
          queryUrl = `${queryUrl}&orderStatus=${obj.providedOrderStatus}`;
        }
        if (obj.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj.lpName}`;
        }
        return this.http.get(queryUrl);
      }

      effectGetTotalOrdersCount(
        obj
      ) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/count`
        if(obj?.lpName){
           queryUrl = `${queryUrl}?lpName=${obj?.lpName}`
          if (obj.startDate) {
            queryUrl = `${queryUrl}&startDate=${obj.startDate.format('x')}`;
          }
        } else{
          if (obj.startDate) {
            queryUrl = `${queryUrl}?startDate=${obj.startDate.format('x')}`;
          }
        }
        // let queryUrl = this.configService.appConfig.appBaseUrl + "orderToDelivery/order/count"
        // if (obj.startDate) {
        //   queryUrl = queryUrl + "?startDate=" + obj.startDate;
        // }
        if (obj.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj.endDate.format('x')}`;
        }
        if (obj.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj.orderPickUpCityId}`;
        }
        if (obj.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj.orderPickUpStoreId}`;
        }
        return this.http.get(queryUrl);
      }

      effectGetCountsByOrderSource(obj?) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/analytics/orderSource/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl =`${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if(obj.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj.lpName}`;
        }
        if(obj.aggregationDate) {
          queryUrl = `${queryUrl}&aggregationDate=${obj.aggregationDate}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        return this.http.get<Record<string,IO2dtimeSlot> | IO2dtimeSlot>(queryUrl);
      }

      effectGetOrdersByAssignedStatus(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/analytics/assignedStatus/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        return this.http.get<Record<string, Record<string, Assigned[]>>>(queryUrl);
      }
      effectGetOrdersByDeliveryStatus(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/analytics/deliveryStatus/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        } 
        if(obj.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj.lpName}`;
        }
        if(obj.aggregationDate) {
          queryUrl = `${queryUrl}&aggregationDate=${obj.aggregationDate}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        queryUrl = `${queryUrl}&groupByField=deliveryStatus`;
        return this.http.get<Record<string, Record<string, IDeliveryStatus>>>(queryUrl);
      }

      effectQueueSizeTrend(obj?) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/queueSizeTrend/`
        if (obj?.startDate) {
      queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl =`${queryUrl} &series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        return this.http.get(queryUrl);
      }

      effectGetAgentDeliveryAttempts(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/agentDeliveryAttempts/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj?.lpName}`;
        }
        return this.http.get<Record<string,IRiderWisePerformance[]>>(queryUrl);
      }

      effectGetTatData(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/analytics/getTatData`;
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if (obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        if (obj?.isPercentageDataModeOn) {
          queryUrl = `${queryUrl}&isPercentageDataModeOn=${obj?.isPercentageDataModeOn}`;
        }
        if(obj?.lpName){
          queryUrl = `${queryUrl}&lpName=${obj?.lpName}`;
        }
        if(obj?.dateField) {
          queryUrl = `${queryUrl}&dateField=${obj?.dateField}`;
        }
        return this.http.get<Record<string,ITatOfOrder[]>>(queryUrl);
      }

      effectGetOrderTemperatureDetails(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/orderTempDetails/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj?.lpName}`;
        }
        return this.http.get<Record<string,IO2dTemperatureGraph[]>>(queryUrl);
      }
      effectGetMmdOrderTemperatureDetails(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/getOrderTempDataInGivenDateRange`;
        if (obj.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj.startDate.format('x')}`;
        }
        if (obj.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj.endDate.format('x')}`;
        }
        if(obj.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj.orderPickUpCityId}`;
        }
        if(obj.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj.orderPickUpStoreId}`;
        }
        if(obj.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj.lpName}`;
        }
        return this.http.get<Record<string,ITemperatureDetails[]>>(queryUrl);
      }

      effectGetAssetsCountInQueue(obj?) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}`;
        const moduleType :string= obj?.graphKey?.split('_')?.[1] || obj.graphKey;
        if(moduleType === 'lmd'){
          queryUrl = `${queryUrl}deliveryManagement/queue/count?moduleType=lmd`;
        }
        else{
          queryUrl = `${queryUrl}deliveryManagement/queue/count`;
        }
        if(obj?.assetType && moduleType === 'lmd') {
          queryUrl = `${queryUrl}&assetType=${obj?.assetType}`;
        }
        if(obj?.assetType && moduleType != 'lmd'){
          queryUrl = `${queryUrl}?assetType=${obj?.assetType}`;
        }
        if (obj?.status && (obj?.status !== 'null')) {
          queryUrl = `${queryUrl}&status=${obj?.status}`;
        }
        if (obj?.startDate) {
          queryUrl = `${queryUrl}&startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if (obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if (obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if (obj?.searchText) {
          queryUrl = `${queryUrl}&search=${obj?.searchText}`;
        }
        return this.http.get(queryUrl);
      }
      effectGetAgentsByAssignedStatus(obj?) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/agentsAssignedUnAssigned/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        return this.http.get(queryUrl);
      }
      effectGetCountByTypes(assetTypes, groups?) {
        let url = `${this.configService.appConfig.appBaseUrl}asset/count?type=${assetTypes}`;
        if (groups) {
          url = `${url}&groups=${groups}`;
        }
        return this.http.get<ICountStatus>(url);
      }
      effectGetAllByType(assetTypes, skip, limit, groups?, fI?, fE?) {
        let url = `${this.configService.appConfig.appBaseUrl}asset?skip=${skip}&limit=${limit}`;
        if (assetTypes) {
          url = `${url}&type=${assetTypes}`;
        }
        if (groups) {
          url = `${url}&groups=${groups}`;
        }
        if (fI) {
          url = `${url}&fI=${fI}`;
        }
        if (fE) {
          url = `${url}&fE=${fE}`;
        }
        return this.http.get<IWarehouseZoneList[]>(url);
      }
      effectGetCarSensorData(obj,assetId) {
        let url = `${this.configService.appConfig.appBaseUrl}gpsData/historicalSensorData/startDate/${obj?.startDate.format('x')}/endDate/${obj?.endDate.format('x')}/assetId/${assetId}`;
        if(obj?.series && obj?.interval){
          url = `${url}?series=${obj?.series}&interval=${obj?.interval}`;
        }
        else if (obj?.series) {
          url = `${url}?series=${obj?.series}`;
        }
        else if(obj?.interval){
          url = `${url}?interval=${obj?.interval}`;
        }
        return this.http.get(url);
      }
      effectGetAllMonitoredLocationForCity(cityId) {
        const url = `${this.configService.appConfig.appBaseUrl}asset/allMonitoredLocation/${cityId}`;
        return this.http.get(url);
      }
      effectGetAssetLinkedByAssetType(baseAssetIds, assetTypeLinked, isCount?, skip?, limit?) {
        let url = `${this.configService.appConfig.appBaseUrl}asset/linkedAssets/${baseAssetIds}/${assetTypeLinked}`;
        if (isCount) {
          url = `${url}?isCount=${isCount}`;
        }
        if (skip || skip == 0) {
          url = `${url}?skip=${skip}`;
        }
        if (limit) {
          url = `${url}&limit=${limit}`;
        }
        return this.http.get<ILocationList[]>(url);
      }
      //kiosk service api's
      effectGetSokTotalAmountByStatus(obj){
        let url = `${this.configService.appConfig.appBaseUrl}sok/analytics/totalAmount?startDateMilliSec=${obj?.startDate.format('x')}&endDateMilliSec=${obj?.endDate.format('x')}&field=${obj?.providedOrderStatus}`;
        if(obj?.pickUpCityIds){
          url = `${url}&pickUpCityIds=${obj?.pickUpCityIds}`;
        }
        if(obj?.pickUpStoreIds){
            url = `${url}&pickUpStoreIds=${obj?.pickUpStoreIds}`;
        }
        if(obj?.orderSource){
          url = `${url}&orderSource=${obj?.orderSource}`;
        }
        return this.http.get<Record<string,number>>(url)
      }
      effectGetSokOrderCountByStatus(obj){
        let url = `${this.configService.appConfig.appBaseUrl}sok/analytics/orderCount?startDateMilliSec=${obj?.startDate.format('x')}&endDateMilliSec=${obj?.endDate.format('x')}`;
        if(obj?.fieldType === 'paymentStatus') {
          url = `${url}&paymentStatus=${obj?.providedOrderStatus}`;
        } else if (obj?.fieldType === 'pickupStatus') {
          url = `${url}&pickupStatus=${obj?.providedOrderStatus}` ;
        }
        if(obj?.pickUpCityIds){
          url = `${url}&pickUpCityIds=${obj?.pickUpCityIds}`
        }
        if(obj?.pickUpStoreIds){
            url = `${url}&pickUpStoreIds=${obj?.pickUpStoreIds}`
        }
        if(obj?.orderSource){
          url = `${url}&orderSource=${obj?.orderSource}`;
        }
        return this.http.get<Record<string,number>>(url)
      }
      effectGetSokOrdersByStatusForGraph(obj){
        let url = `${this.configService.appConfig.appBaseUrl}sok/analytics/orderCountGrp?startDateMilliSec=${obj?.startDate.format('x')}&endDateMilliSec=${obj?.endDate.format('x')}&groupByField=${obj?.groupByField}`;
        if(obj?.fieldType === 'paymentStatus') {
          url = `${url}&paymentStatus=${obj?.status}`;
        } else if (obj?.fieldType === 'pickupStatus') {
          url = `${url}&pickupStatus=${obj?.status}`;
        }
        if(obj?.series) {
          url = `${url}&series=${obj?.series}`;
        }
        if(obj?.pickUpCityIds){
          url = `${url}&pickUpCityIds=${obj?.pickUpCityIds}`;
        }
        if(obj?.pickUpStoreIds){
            url = `${url}&pickUpStoreIds=${obj?.pickUpStoreIds}`;
        }
        if(obj?.orderSource){
          url = `${url}&orderSource=${obj?.orderSource}`;
        }
        return this.http.get<Record<string,ITotalOrderKiosk[]>>(url)
      }

      effectGetSokTotalAmountByStatusForGraph(obj){
        let url = `${this.configService.appConfig.appBaseUrl}sok/analytics/revenueGrp?startDateMilliSec=${obj?.startDate.format('x')}&endDateMilliSec=${obj?.endDate.format('x')}&groupByField=${obj?.groupByField}&field=${obj?.status}`;
        if(obj?.series) {
          url = `${url}&series=${obj?.series}`;
        }
        if(obj?.pickUpCityIds){
          url = `${url}&pickUpCityIds=${obj?.pickUpCityIds}`;
        }
        if(obj?.pickUpStoreIds){
            url = `${url}&pickUpStoreIds=${obj?.pickUpStoreIds}` ;
        }
        if(obj?.orderSource){
          url = `${url}&orderSource=${obj?.orderSource}`;
        }
        return this.http.get<Record<string,ITotalOrderKiosk[]>>(url)
      }

      getSokTopProducts(obj ,topN=5){
        let url = `${this.configService.appConfig.appBaseUrl}sok/analytics/topProductsOrdered?startDateMilliSec=${obj?.startDate.format('x')}&endDateMilliSec=${obj?.endDate.format('x')}`;
        if(obj?.series) {
          url = `${url}&series=${obj?.series}`;
        }
        if(obj?.pickUpCityIds){
          url = `${url}&pickUpCityIds=${obj?.pickUpCityIds}` ;
        }
        if(obj?.pickUpStoreIds){
            url = `${url}&pickUpStoreIds=${obj?.pickUpStoreIds}`;
        }
        if(topN){
            url = `${url}&topN=${topN}`;
        }
        if(obj?.orderSource){
          url = `${url}&orderSource=${obj?.orderSource}`;
        }
        return this.http.get<Record<string,ITotalOrderKiosk[]>>(url)
      }

      effectGetKioskSalesByDineTypes(obj){
        let url = `${this.configService.appConfig.appBaseUrl}sok/analytics/dineTypeSales?startDateMilliSec=${obj?.startDate.format('x')}&endDateMilliSec=${obj?.endDate.format('x')}&reportType=graph`;
        if(obj?.series) {
          url = `${url}&series=${obj?.series}`;
        }
        if(obj?.pickUpCityIds){
          url = `${url}&pickUpCityIds=${obj?.pickUpCityIds}` ;
        }
        if(obj?.pickUpStoreIds){
            url = `${url}&pickUpStoreIds=${obj?.pickUpStoreIds}`;
        }
        if(obj?.orderSource){
          url = `${url}&orderSource=${obj?.orderSource}`;
        }
        return this.http.get<Record<string,ITotalOrderKiosk[]>>(url)
      }

      effectGetAnalyticsDataByGrpId(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/analytics/genericGrpdata`;
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.deliveryStatus) {
          queryUrl = `${queryUrl}&deliveryStatus=${obj?.deliveryStatus}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        if(obj?.grpId) {
          queryUrl = `${queryUrl}&grpId=${obj?.grpId}`;
        }
        if(obj?.sumId) {
          queryUrl = `${queryUrl}&sumId=${obj?.sumId}`;
        }
        if(obj?.dateField) {
          queryUrl = `${queryUrl}&dateField=${obj?.dateField}`;
        }
        return this.http.get<Record<string,ITotalOrderKiosk[]>>(queryUrl);
      }

      effectGetTotalOrderCounts(obj,type=null) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/assignment/group/all-v2`

        if(type==='dashboard'){
          queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/group/count`
        }

        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          if(type==='dashboard'){
            queryUrl = `${queryUrl}&orderPickUpCityIds=${obj?.orderPickUpCityId}`;
          }else{
            queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
          }
        }
        if(obj?.orderPickUpStoreId) {
          if(type==='dashboard'){
            queryUrl = `${queryUrl}&orderPickUpStoreIds=${obj?.orderPickUpStoreId}`;
          }else{
            queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
          }
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        if(obj?.isCount) {
          queryUrl = `${queryUrl}&isCount=${obj?.isCount}`;
        }
        if(obj?.module) {
          queryUrl = `${queryUrl}&module=${obj?.module}`;
        }
        return this.http.get<ICountStatus>(queryUrl);
      }
      effectGetTotalOrderCountsForGraph(obj) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/group`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl = `${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityIds=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpCityStoreIds=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj?.lpName}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        if(obj?.module) {
          queryUrl = `${queryUrl}&module=${obj?.module}`;
        }
        return this.http.get<Record<string,IO2dtimeSlot> | IO2dtimeSlot>(queryUrl);
      }



      effectRoutePlansAndTempData(obj?) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/group/routePlan/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl =`${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityIds=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreIds=${obj?.orderPickUpStoreId}`;
        }
        if(obj.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj.lpName}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        return this.http.get(queryUrl);
      }

      effectGetMmdDeliveredGroupAnalytics(obj){
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/groupAnalytics/`+obj.groupBy+`/?skip=${obj?.skip ?? 0}&limit=${obj?.limit ?? 20}`
        if (obj?.isCount) {
          queryUrl = `${queryUrl}&isCount=${obj?.isCount}`;
        }
        if (obj?.startDate) {
          queryUrl = `${queryUrl}&startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityIds=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreIds=${obj?.orderPickUpStoreId}`;
        }
        if(obj?.category) {
          queryUrl = `${queryUrl}&category=${encodeURIComponent(obj?.category)}`;
        }
        if(obj?.carrierIds) {
          queryUrl = `${queryUrl}&carrierIds=${obj?.carrierIds}`;
        }
        if(obj?.sortByFieldsStr) {
          queryUrl = `${queryUrl}&sortByFieldsStr=${obj?.sortByFieldsStr}`;
        }
        if(obj?.deliveryStatus) {
          queryUrl = `${queryUrl}&deliverystatus=${obj?.deliveryStatus}`;
        }
        return this.http.get<IMmdDeliveredGroupAnalytics[]>(queryUrl);
      }

      effectRoutePlansAssignedStatusData(obj?) {
        let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/analytics/group/routePlan/`
        if (obj?.startDate) {
          queryUrl = `${queryUrl}?startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.series) {
          queryUrl =`${queryUrl}&series=${obj?.series}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityIds=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpStoreId) {
          queryUrl = `${queryUrl}&orderPickUpStoreIds=${obj?.orderPickUpStoreId}`;
        }
        if(obj.lpName) {
          queryUrl = `${queryUrl}&lpName=${obj.lpName}`;
        }
        if(obj?.isDashBoard) {
          queryUrl = `${queryUrl}&isDashBoard=${obj?.isDashBoard}`;
        }
        return this.http.get(queryUrl);
      }


      effectGetO2dOverviewAnalytics(obj){
        const queryUrl = this.buildEffectGetO2dOverviewAnalyticsUrl(obj)
        return this.http.get<IO2DOverviewAnalytics>(queryUrl);
      }

      async effectGetO2dOverviewAnalyticsDownlaod(obj){
        const queryUrl = this.buildEffectGetO2dOverviewAnalyticsUrl(obj)
        await this.downloadService.downloadFilesViaObservable(queryUrl, 'OrderSummaryReport.xlsx');
      }

      buildEffectGetO2dOverviewAnalyticsUrl(obj){
        let queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/report/orderSummary?groupType=`+obj.groupType
        if (obj?.startDate) {
          queryUrl = `${queryUrl}&startDate=${obj?.startDate.format('x')}`;
        }
        if (obj?.endDate) {
          queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
        }
        if(obj?.orderPickUpCityId) {
          queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
        }
        if(obj?.orderPickUpLocationId) {
          queryUrl = `${queryUrl}&orderPickUpLocationId=${obj?.orderPickUpLocationId}`;
        }
        if(obj?.lpId) {
          queryUrl = `${queryUrl}&logisticProvider=${obj?.lpId}`;
        }
        if(obj?.filterType) {
          queryUrl = `${queryUrl}&filterType=${obj?.filterType}`;
        }
        if(obj?.reportType) {
          queryUrl = `${queryUrl}&reportType=${obj?.reportType}`;
        }
        return queryUrl;
      }
}