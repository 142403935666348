import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {ActiveOrdersMin, GeoFenceCount, GeoFenceObject, TrackerIcons, VehicleObject} from './models/fleet.models';
import {Observable} from 'rxjs';
import {ActiveOrderGroup, EventData, EventStatus, GeoAsset} from './models/custom.types';
import {TrackerMapIconsSettings} from '../../app/settings/settings-models';

import moment from 'moment-timezone';
import { ILocation } from '../Assets/interfaces/IAsset.types.modal';
import { ISensorData, IloadAssetsLinkedData } from './interface';
// import { ILoadSpeedData } from './interface';

@Injectable({providedIn: 'root'})
export class DataService {
  base = this.configService.appConfig.appBaseUrl;

  constructor(private http: HttpClient, private configService: ConfigService) {
  }

  getAllTrakableVehicles(moduleType, isCount, fInclude?, fExclude?, assetIds?): Observable<VehicleObject[]> {
    let url = `${this.base}asset/trackableDevice/${moduleType}`;
    url = `${url}?isCount=${isCount}`;
    if (fInclude) {
      url = `${url}&fI=${fInclude}`;
    }
    if (fExclude) {
      url = `${url}&fE=${fExclude}`;
    }
    if (assetIds) {
      url = `${url}&assetIds=${assetIds}`;
    }
    return this.http.get<VehicleObject[]>(url);
  }

  getTrackIconsByModuleType(moduleType, assetType?): Observable<TrackerIcons> {
    let url = `${this.base}deliveryManagement/settings/trackIcons/${moduleType}`;
    if (assetType) {
      url = `${url}?assetType=${assetType}`;
    }
    return this.http.get<TrackerIcons>(url);
  }

  getAssetDetails(assetId): Observable<VehicleObject> {
    const url = `${this.base}asset/${assetId}`;
    return this.http.get<VehicleObject>(url);
  }

  getCarDistanceMovedData(startDate: number, endDate: number, assetId) {
    const url = `${this.base}gpsData/distanceMovedV2/startDate/${startDate}/endDate/${endDate}/assetId/${assetId}`;
    return this.http.get(url);
  }

  getCarAnalyticssHistoricDataById(startDate, endDate, assetId) {
    const url = `${this.base}gpsData/historicalV2/startDate/${startDate}/endDate/${endDate}/assetId/${assetId}`;
    return this.http.get(url);
  }

  getCarSensorData(startDate, endDate, assetId, series?) {
    let url = `${this.base}gpsData/historicalSensorData/startDate/${startDate}/endDate/${endDate}/assetId/${assetId}`;
    if (series) {
      url = `${url}?series=${series}`;
    }
    return this.http.get<ISensorData[]>(url);
  }

  getCarEventAndStatusData(startDate, endDate, assetId, skip?, limit?, type?, status?) {
    let url = `${this.base}trackerEvent/all-v2/assetId/${assetId}?startDate=${startDate}&endDate=${endDate}&skip=${(skip || skip == 0) ? skip : ''}&limit=${limit ? limit : ''}`;
    if (type) {
      url = `${url}&type=${type}&status=${status}`;
    }
    return this.http.get<Array<EventData>>(url);
  }

  getCarEventAndStatusCount(startDate, endDate, assetId, type?, status?) {
    return this.http.get<{
      count: number
    }>(`${this.base}trackerEvent/all-v2/count/assetId/${assetId}?startDate=${startDate}&endDate=${endDate}&type=${type ? type : ''}&status=${status ? status : ''}`);
  }

  getAllCarEventsAndStatus(type?: string) {
    type = type ? `?type=${type}` : '';
    return this.http.get<EventStatus>(`${this.base}trackerEvent/types${type}`);
  }

  getLinkedAssets(assetId) {
    return this.http.get<IloadAssetsLinkedData[]>(`${this.base}asset/linkedAssets/${assetId}`);
  }

  getAllActiveOrdersForAgent(agentId, isMinifiedData?): Observable<ActiveOrdersMin[]> {
    let queryUrl = `${this.base}deliveryManagement/management/activeOrders/agentId/${agentId}`;
    if (isMinifiedData) {
      queryUrl = `${queryUrl}?min=${isMinifiedData}`;
    }
    return this.http.get<ActiveOrdersMin[]>(queryUrl);
  }


  getActiveOrdersGroup(moduleType: string, carrierId?: string) {
    const fi = '&fI=deliveryOrderStatus,cancelledDeliveries,agentId,pickUpLocation';
    const carrierIds = carrierId ? `&carrierIds=${carrierId}` : '';
    const url = `${this.base}deliveryManagement/assignment/group/all-v2?moduleType=${moduleType}${carrierIds}&status=pending,returning&skip=0&limit=1000&isLinked=true${fi}`;
    return this.http.get<ActiveOrderGroup[]>(url);
  }


  getAll(): Observable<GeoFenceObject[]> {
    return this.http.get<GeoFenceObject[]>(`${this.base}geoFence/`);
  }

  getGeoAssetsById(id: string): Observable<GeoAsset[]> {
    return this.http.get<GeoAsset[]>(`${this.base}geoFence/all/assets/geoFenceId/${id}`);
  }

  getAllGeoFenceAssetCount(): Observable<GeoFenceCount[]> {
    return this.http.get<GeoFenceCount[]>(`${this.base}geoFence/allGeoFenceAssetCount`);
  }

  addGeofence(newGeoFenceData) {
    return this.http.post(`${this.base}geoFence/`, newGeoFenceData);
  }

  deleteById(id) {
    if(id){
      return this.http.delete(`${this.base}geoFence/id/${id}`);
    }
  }

  getMapIconConfigurationSettingsByModuleType(moduleType) {
    let url = `${this.base}account/trackerModule/settings`;
    if (moduleType) {
      url = `${url}?pReq=moduleDetails.${moduleType}`;
    }
    return this.http.get<TrackerMapIconsSettings>(url);
  }

  updateMapIconConfigurationSettingsByModuleType(moduleType, payload) {
    return this.http.put(`${this.base}account/trackerModule/settings/moduleType/${moduleType}`, payload);
  }


  buildFuelDataForLastTwoHours() {
    const start = moment().subtract(2, 'hours');
    const dateArr = [];
    let s = start;
    let fuelInitVal = getRandomInt(10, 60);

    for (let i = 0; i < 12; i++) {
     s = moment(s).add(10, 'minutes');
     if(i==2)
     fuelInitVal = fuelInitVal-1
     if(i==6)
     fuelInitVal = fuelInitVal-1
     if(i==10)
     fuelInitVal = fuelInitVal-1
     let obj = {
       'updated': s,
       'fuel': fuelInitVal
     }

      dateArr.push(obj)
   }
    return dateArr;
  }

  getLinkedLocations(cityId){
    const url = `${this.base}asset/linkedAssets/${cityId}/location`;
    return this.http.get<ILocation[]>(url);
  }

  getEvetnsData(agentId: string, type: string, subType: string, complianceType: string, startDate: number, endDate: number, skip: number, limit: number) {
    let url = this.configService.appConfig.appBaseUrl + `eventsV2/all/assetId/${agentId}?startDate=${startDate}&endDate=${endDate}&type=${type}&complianceType=${complianceType}`
    if (skip) {
      url = `${url}&skip=${skip}`;
    }
    if (limit) {
      url = `${url}&limit=${limit}`;
    }
    if (subType) {
      url = `${url}&subType=${subType}`;
    }

    return this.http.get(url)
  }

  getEvetnsSubTypesData(agentId: string, startDate: number, endDate: number) {
    let url = this.configService.appConfig.appBaseUrl + `eventsV2/allTypesSubtypes?startDate=${startDate}&endDate=${endDate}&assetId=${agentId}`

    return this.http.get(url)
  }
}

export function getRandomInt(min, max) {
  let random = Math.floor(Math.random() * max);
  if(random <= min) {
    random = 15;
  }
  return random
}
